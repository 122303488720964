/* SignUp.css */
* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.signup-container {
  max-width: 400px;
  margin: 2rem auto;
  text-align: left;
  font-family: Arial, sans-serif;
}

.signup-container h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.25rem;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  margin-top: 25px;
}

.submit-button {
  padding: 0.5rem 1rem;
  background-color: #111;
  color: #fff;
  border: none;
  /* border-radius: 8px; */
  cursor: pointer;
  display: block;
  width: 100%;
  max-width: 400px;
}

.submit-button:hover {
  background-color: #333;
}

.status-message {
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
}

.status-message.success {
  color: green;
}

.status-message.error {
  color: red;
}

button {
  border-radius: 12px;
  font-size: 1rem;
}